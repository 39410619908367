@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
@import "./fonts";
@import "./hubspot-forms";

:root {
	--edge-spacing: 16px;
	--content-max-width: calc(1456px + (var(--edge-spacing) * 2));
	--menu-open-speed: 0.5s;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	background: $primary-fog-50;
	color: $primary-midnight-700;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		color: $primary-midnight-900;
	}

	&.removeScroll {
		overflow: hidden !important;
		overscroll-behavior: contain;
		position: relative !important;
		padding-left: 0;
		padding-top: 0;
		padding-right: 0;
		margin-left: 0;
		margin-top: 0;
	}

	& main {
		display: flex;
		flex-direction: column;
		flex: 1;
		margin-bottom: rem(128);
	}

	& img {
		display: block;
		font-style: italic;
		font-size: rem(14);
	}

	& a {
		// @include regularLink; Why?
		text-decoration: none;
	}

	& * {
		box-sizing: inherit;
	}

	& .container {
		max-width: calc(var(--content-max-width));
		margin: 0 auto;
		width: 100%;
		padding-inline: var(--edge-spacing);
	}


	.builder-text {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 600;
			&:not(:first-child) {
				margin-top: rem(38);
			}
		}

		p {
			line-height: 150%;
		}

		ul,
		p {
			margin: rem(23) 0 !important;
		}

		p:first-child {
			margin: 0 !important;
		}

		a {
			color: $secondary-steel-900;
			text-decoration: underline;

			&:hover {
				color: $secondary-steel-500;
			}

			&:active {
				color: $primary-midnight-900;
			}

			&:focus {
				outline: 2px solid $information-300;
			}
		}

		sup {
			line-height: 0;
		}

		ol,
		ul {
			padding: 0;
			li {
				position: relative;
				list-style-type: none;
				line-height: 150%;
				padding-left: rem(24);
				&::before {
					margin-left: rem(8);
					color: $primary-midnight-700;
					position: absolute;
					left: 0;
				}
			}
		}

		ul li {
			&::before {
				content: "•";
			}
		}

		ol {
			counter-reset: item;
			li {
				counter-increment: item;
				&::before {
					content: counter(item) ".";
				}
			}
		}
	}

	@media print {
		header,
		footer,
		nav {
			display: none !important;
		}
	}

	.ToastViewport {
		--viewport-padding: 25px;
		position: fixed;
		bottom: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		padding: var(--viewport-padding);
		gap: 10px;
		width: 390px;
		max-width: 100vw;
		margin: 0;
		list-style: none;
		z-index: 2147483647;
		outline: none;
	}
	#onetrust-consent-sdk #onetrust-banner-sdk {
		background-color: #FFFFFF;
		pointer-events: auto !important;
	  }
}
