form.hs-form {
	display: flex;
	flex-direction: column;
	gap: rem(20);
	width: 100%;

	// &#hsForm_477b33ec-5eed-45b4-aa31-3e5808530706 {
	// 	background-color: greenyellow !important;
	// 	display: block;

	// 	fieldset {
	// 		max-width:100% !important;
	// 		width:66.666%;
	// 		display:inline-block;
	// 		vertical-align:bottom;
	//  }

	// 	& > div.hs_email.hs-email.hs-fieldtype-text.field.hs-form-field .input {
	// 		max-width: 70%;
	// 	}

	// 	& > div.hs_submit {
	// 		vertical-align:bottom;
	//    }
	// }

	fieldset {
		max-width: unset;
		width: 100%;
		display: flex;
		gap: 16px;

		@media (max-width: 1024px) {
				flex-direction: column;
				
				&.form-columns-2 .hs-form-field {
					width: 100% !important;
				}
		}
	}

	h3 {
		margin-block: 0;
		color: $primary-midnight-900;
		font-size: rem(19);
		line-height: 150%;
		font-weight: 700;
	}

	p {
		margin-block: 0;
		color: $primary-midnight-900;
		font-size: rem(16);
		line-height: 150%;
		font-weight: 400;
	}

	a {
		@include underlinedLink;
	}

	.input {
		margin: 0 !important;
	}

	.hs-form-field {
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 100%;
		label {
			font-size: 16px;
			font-weight: 600;
			color: $primary-midnight-900;
			.hs-form-required {
				margin-left: 4px;
				color: $error-700;
			}
		}


		input.hs-input {
			border: 1px solid $primary-fog-900;
			border-radius: 2px;
			background-color: white;
			padding: 8px 12px;
			@include paragraph03;
			color: $primary-midnight-700;
			margin-bottom: 0;
			width: 100%;

			&:hover {
				border-color: $primary-midnight-300;
			}

			&:focus-visible {
				outline: 1px solid $information-300;
				border-color: $information-300;
			}

			&:disabled {
				background-color: $primary-fog-300;
				border-color: $primary-fog-900;
				color: $primary-midnight-700;
			}

			&.error {
				border-color: $error-700;
			}

			&[type='checkbox'] {
				border-width: 1px;
				accent-color: $secondary-steel-900;
			}
		}
	}

	.hs-error-msgs {
		margin: 0;
		padding: 0;
		list-style: none;
		color: $error-700 !important;
		font-size: rem(12) !important;
		line-height: 150%;
		font-weight: 400 !important;

		label {
			color: $error-700 !important;
			font-size: rem(12) !important;
			line-height: 150%;
			font-weight: 400 !important;
		}
	}

	.hs-field-desc {
		font-size: 13px;
		font-weight: 400;
		color: $primary-midnight-700;
	}

	ul.inputs-list {
		padding: 0;
		margin: 0;
		li {
			position: relative;
			list-style-type: none;
			line-height: 150%;
			width: 100%;
			margin-block: 8px;

			label {
				display: inline-flex;
				width: 100%;
				gap: 14px;
				font-size: 16px;
				font-weight: 400;
				align-items: center;

				input {
					width: 24px !important;
					height: 24px !important;
					margin: 0 !important;
				}

				span {
					margin: 0 !important;
				}
			}
		}

		&.hs-error-msgs {
			li {
				margin: 0;
			}
		}
	}

	select {
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		height: 35px;
		width: 100% !important;
		border: 1px solid $primary-fog-900;
		border-radius: 2px;
		background-color: white;
		color: $primary-midnight-700;
		padding: 8px 12px;
		@include paragraph03;

		&:hover {
			border-color: $primary-midnight-300;
		}

		&:focus-visible {
			outline: 1px solid $information-300;
			border-color: $information-300;
		}

		&:disabled {
			background-color: $primary-fog-300;
			border-color: $primary-fog-900;
			color: $primary-midnight-700;
		}

		&.error {
			border-color: $error-700;
		}

		&[data-placeholder] {
			color: $primary-midnight-700;
		}
	}

	.hs-dependent-field {
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: 100%;
	}

	[type='submit'] {
		box-sizing: border-box;
		font-family: inherit;
		border: solid 1px transparent;
		border-radius: rem(4);
		padding: rem(9) rem(24);
		font-size: rem(16);
		font-weight: 600;
		text-decoration: none;
		cursor: pointer;
		text-align: center;
		min-height: rem(48);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: rem(8);
		width: fit-content;

		background-color: $primary-cardinal-900;
		color: $shade-white;

		&:hover {
			background-color: $shade-white;
			color: $primary-cardinal-900;
			border-color: $primary-cardinal-900;
		}

		&:active,
		&.active {
			color: $shade-white;
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
		}

		&:focus,
		&:focus-within,
		&:focus-visible {
			background-color: $primary-cardinal-900;
			border-color: $information-300;
			outline: 2px solid $information-300;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			background-color: $primary-cardinal-300;
			border-color: $primary-cardinal-300;
			color: $shade-white;
		}
	}

	.hs-richtext {
		margin: 0 !important;
	}

	.hs-form-booleancheckbox {
		margin-top: 14px !important;
		margin-bottom: 8px !important;
	}

	textarea.hs-input {
		border: 1px solid $primary-fog-900;
		border-radius: 2px;
		background-color: white;
		padding: 8px 12px;
		@include paragraph03;
		color: $primary-midnight-700;
		margin-bottom: 0;
		width: 100% !important;
		min-height: 100px;

		&:hover {
			border-color: $primary-midnight-300;
		}

		&:focus-visible {
			outline: 1px solid $information-300;
			border-color: $information-300;
		}

		&:disabled {
			background-color: $primary-fog-300;
			border-color: $primary-fog-900;
			color: $primary-midnight-700;
		}

		&.error {
			border-color: $error-700;
		}
	}
}

#hbspt-form-3c1194bf-10ac-4d1d-9253-88c24c9c34b9 {
	background-color: goldenrod !important;
	// display: block !important;
}

#hsForm_477b33ec-5eed-45b4-aa31-3e5808530706 > div.hs_submit.hs-submit {
	display: inline;
}