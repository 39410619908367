h1 {
	font-size: rem(33);
	line-height: 120%;
}

h2 {
	font-size: rem(28);
}

h3 {
	font-size: rem(23);
}

h4 {
	font-size: rem(19);
}

p {
	font-size: rem(19);
	line-height: 150%;
}

caption {
	font-size: rem(13);
}